@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}

#root h2 {
  font-size: 48px;
}
